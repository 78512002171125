<template>
  <v-container fluid class="backgroundLoginZipay fill-height">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-img
          width="250"
          class="white--text align-end"
          src="https://res.cloudinary.com/zipay/image/upload/v1668069564/deposit-dashboard/Logo_ZIPAY_full_color_1_rsbvho.png"></v-img>
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="10">
                  <h1>Login</h1>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    color="success"
                    label="Email"
                    placeholder="Enter your email address"
                    outlined
                    name="email"
                    type="text"
                    hide-details="auto"
                    @keydown.enter="jump()"
                    required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field
                    v-model="password"
                    :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show4 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    placeholder="Enter your password"
                    outlined
                    color="success"
                    @keydown.enter="jump()"
                    hide-details="auto"
                    @click:append="show4 = !show4">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="10">
                  <div class="d-flex justify-end">
                    <RouterLink to="/forgot-password">Forgot Password?</RouterLink>
                  </div>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-btn
                    @click="login"
                    :disabled="!valid || loading"
                    :loading="loading"
                    width="100%"
                    color="zipay"
                    class="elevation-0">
                    login
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="10">
                  Don't have account? <RouterLink to="/register">Sign up now</RouterLink>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      show4: false,
      loading: false,
      disabled: false,
      valid: false,
      password: '',
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 50 || 'Max 50 characters',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    ...mapActions('authModule', ['loggedIn']),
    jump() {
      if (this.valid) {
        this.disabled = true;
        this.login()
          .catch(() => {
            // ERROR HANDLING MISAL USERNAME/PASSWORD WRONG
          });
      }
    },
    async login() {
      this.loading = true;
      await this.loggedIn({
        email: this.email,
        password: this.password
      })
      this.loading = false;
    }
  },
};
</script>

<style scoped>
  .backgroundLoginZipay {
    background: url('https://res.cloudinary.com/zipay/image/upload/v1667964321/deposit-dashboard/Merchant-Dashboard-image_vwwwl7.png')
      no-repeat fixed bottom left !important;
    width: 100%;
    height: 100%;
    position: absolute;
  }
</style>
